import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileNav from "../../Components/ProfileNav";
import axios from "../../api/axios";
import "../../CSS/Profile.css";
import excl from "../../Icons/exclamation-lg.svg";
import tick from "../../Icons/check-lg.svg";

const Upload = () => {
  const [name, setName] = useState();
  const [address, setAddress] = useState();
  const [state, setState] = useState();
  const [LGA, setLGA] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [assignedDepartment, setAssignedDepartment] = useState();
  const [jobDescription, setJobDescription] = useState();
  const [resumptionDate, setResumptionDate] = useState();
  const [expectedEndDate, setExpectedEndDate] = useState();
  const [errMessage, setErrMessage] = useState();
  const [uploadFail, setUploadFail] = useState();
  const [success, setSucess] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()

  useEffect(() => {
    document.title = "Work Details Upload";
  }, []);

  const formData = {
    name,
    address,
    state,
    LGA,
    email,
    phone,
    assignedDepartment,
    jobDescription,
    resumptionDate,
    expectedEndDate
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploadFail(false);
    setLoading(true);
    try {
      const res = await axios.post(
        `/api/student/workDetails`,
        JSON.stringify(formData),
        {
          headers: { "Content-type": "application/json" }
        }
      );
      setSucess(true);
      setLoading(false);
      setUploadFail(false);
      setTimeout(() => {
        navigate("/students/index");
      }, 2000);
    } catch (err) {
      setLoading(false);
      setUploadFail(true);
      err.response.data.errors
        ? setErrMessage(err.response.data.errors)
        : setErrMessage(err.response.data.message);
    }
  };

  return (
    <div>
      <ProfileNav />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="heading">
            <h2 className="profile-header">Work Details Upload</h2>
          </div>

          <div className="work-form-wrapper">
            <form className="grid-small">
              <div className="work-form">
                <div className="work-form-half half-1">
                  <label htmlFor="company-name">
                    <span className="label">Company Name</span>
                    <input
                      type="text"
                      name="company-name"
                      id="company-name"
                      className="work-form-input"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </label>

                  <label htmlFor="company-address">
                    <span className="label">Company Address</span>
                    <textarea
                      name="company-address"
                      id="company-address"
                      cols="20"
                      rows="2"
                      className="work-form-input"
                      onChange={(e) => setAddress(e.target.value)}
                      required
                    ></textarea>
                  </label>

                  <label htmlFor="company-state">
                    <span className="label">State</span>
                    <input
                      type="text"
                      name="company-state"
                      id="company-state"
                      className="work-form-input"
                      onChange={(e) => setState(e.target.value)}
                      required
                    />
                  </label>

                  <label htmlFor="LGA">
                    <span className="label">LGA</span>
                    <input
                      type="text"
                      name="LGA"
                      id="LGA"
                      className="work-form-input"
                      onChange={(e) => setLGA(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div className="work-form-half half-2">
                  <label htmlFor="office-email">
                    <span className="label">Office Email</span>
                    <input
                      type="text"
                      name="office-email"
                      id="office-email"
                      onChange={(e) => setEmail(e.target.value)}
                      className="work-form-input"
                      required
                    />
                  </label>

                  <label htmlFor="office-number">
                    <span className="label">Office Phone Number</span>
                    <input
                      type="text"
                      name="office-number"
                      id="office-number"
                      onChange={(e) => setPhone(e.target.value)}
                      className="work-form-input"
                      required
                    />
                  </label>

                  <label htmlFor="res-date">
                    <span className="label">Resumption Date</span>
                    <input
                      type="date"
                      name="res-date"
                      id="res-date"
                      onChange={(e) => setResumptionDate(e.target.value)}
                      className="work-form-input"
                      required
                    />
                  </label>

                  <label htmlFor="term-date">
                    <span className="label">Termination Date</span>
                    <input
                      type="date"
                      name="term-date"
                      id="term-date"
                      className="work-form-input"
                      onChange={(e) => setExpectedEndDate(e.target.value)}
                      required
                    />
                  </label>

                  <label htmlFor="dept">
                    <span className="label">Assigned Department</span>
                    <input
                      type="text"
                      name="dept"
                      id="dept"
                      className="work-form-input"
                      onChange={(e) => setAssignedDepartment(e.target.value)}
                      required
                    />
                  </label>
                  <label htmlFor="desc">
                    <span className="label">Job Description</span>
                    <input
                      type="text"
                      name="desc"
                      id="desc"
                      className="work-form-input"
                      onChange={(e) => setJobDescription(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </div>
              <button className="submit-upload" onClick={handleSubmit}>
                {loading ? (
                  <div className="center-load">
                    <span className="basic"></span>
                  </div>
                ) : (
                  `Upload Work Details`
                )}
              </button>
              <div className={uploadFail ? "post-fail" : ""}>
                {uploadFail ? (
                  <div className="result-icon">
                    <img src={excl} />
                    {errMessage}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={success ? "post-success" : ""}>
                {success ? (
                  <div className="result-icon">
                    <img src={tick} />
                    Work Upload Successful!
                  </div>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upload;
