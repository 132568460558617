import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";
import HomeNav from "../Components/HomeNav";
import passwordEye from "../Icons/eye.svg";
import passwordEyeSlash from "../Icons/eye-slash.svg";
import HomeFooter from "../Components/HomeFooter";
import { facultyOptions, departmentOptions, courseOptions } from "./Schools";
import "../CSS/Login.css";
import excl from "../Icons/exclamation-lg.svg";
import tick from "../Icons/check-lg.svg";
import BankData from './banks.json';

const Register = () => {
  useEffect(() => {
    document.title = "Registration";
  }, []);
  const email = sessionStorage.getItem('userEmail');
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [middleName, setMiddleName] = useState();
  const [sex, setSex] = useState();
  // const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [faculty, setFaculty] = useState();
  const [department, setDepartment] = useState();
  const [facultyData, setFacultyData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [matricNo, setMatricNo] = useState();
  const [level, setLevel] = useState();
  const [course, setCourse] = useState();
  const [bankList, setBankList] = useState([]);
  const [bankDetails, setBankDetails] = useState({
    name: "",
    accountNumber: "",
    sortCode: ""
  });
  const [courseData, setCourseData] = useState([]);
  const [password, setPassword] = useState();
  const [success, setSucess] = useState(false);
  const [regFail, setRegFail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState();
  const [passwordShow, setPasswordShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setFacultyData(facultyOptions);
  }, []);

  useEffect(() => {
    setBankList(BankData);
  }, []);

  const regData = {
    firstName,
    middleName,
    lastName,
    course,
    department,
    email,
    matricNo,
    sex,
    level,
    faculty,
    phone,
    password,
    bankDetails
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setRegFail(false);
    try {
      const res = await axios.post(
        `/api/auth/register`,
        JSON.stringify(regData),
        {
          headers: { "Content-type": "application/json" }
        }
      );
      setSucess(true);
      setLoading(false);
      setRegFail(false);

      localStorage.removeItem('verification'); // remove verification from local storage to reenable OTP

      setTimeout(() => {
        navigate("/studentlogin");
      }, 2000);
    } catch (err) {
      setRegFail(true);
      setLoading(false);
      err.response.data.errors
        ? setErrMessage(err.response.data.errors)
        : setErrMessage(err.response.data.message);
    }
  };

  const toggleShow = () => {
    setPasswordShow(!passwordShow);
  };

  const handleFaculty = (schoolName) => {
    const data = departmentOptions.filter((x) => x.schoolName === schoolName);
    setDepartmentData(data);
  };

  const handleDept = (deptName) => {
    const data = courseOptions.filter((x) => x.deptName === deptName);
    setCourseData(data);
  };

  return (
    <div>
      <HomeNav />
      <div className="content-container1 page one-reg">
        <div className="form-container reg">
          <form>
            <h1 className="main-header">Student Registration</h1>
            <h3 className="sub-header">Please Enter Your Details Carefully</h3>

            <div className="reg-input-section">
              <div className="reg-block">
                <label htmlFor="firstName" className="obj-description">
                  First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  id="first-name"
                  className="reg-form-input"
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>

              <div className="reg-block">
                <label htmlFor="lastName" className="obj-description">
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  id="last-name"
                  className="reg-form-input"
                  required
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>

            <div className="reg-input-section">
              <div className="reg-block">
                <label htmlFor="middleName" className="obj-description">
                  Middle Name
                </label>
                <input
                  type="text"
                  name="middleName"
                  id="middle-name"
                  className="reg-form-input"
                  onChange={(e) => setMiddleName(e.target.value)}
                  required
                />
              </div>

              <div className="reg-block">
                <label htmlFor="sex" className="obj-description">
                  Sex
                </label>
                <select
                  name="sex"
                  id="sex"
                  className="reg-form-input"
                  onChange={(e) => setSex(e.target.value)}
                  required
                >
                  <option value=""></option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
            </div>

            <div className="reg-input-section">
              <div className="reg-block">
                <label htmlFor="email" className="obj-description">
                  Email(Your Babcock Email)
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="reg-form-input"
                  placeholder="Eg: john1321@student.babcock.edu.ng"
                  value={email}
                  required
                  disabled
                />
              </div>

              <div className="reg-block">
                <label htmlFor="phoneNumber" className="obj-description">
                  Phone Number
                </label>
                <input
                  type="text"
                  name="phoneNumber"
                  id="phone-number"
                  className="reg-form-input"
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="reg-input-section">
              <div className="reg-block">
                <label htmlFor="school" className="obj-description">
                  School/Faculty
                </label>
                <select
                  name="school"
                  id="school"
                  className="reg-form-input"
                  onChange={(e) => {
                    setFaculty(e.target.value);
                    handleFaculty(e.target.value);
                  }}
                >
                  <option value="">Select School</option>
                  {facultyData && facultyData !== undefined
                    ? facultyData.map((fclty, index) => {
                        return (
                          <option key={index} value={fclty.value}>
                            {fclty.value}
                          </option>
                        );
                      })
                    : "No Faculty"}
                </select>
              </div>

              <div className="reg-block">
                <label htmlFor="department" className="obj-description">
                  Department
                </label>
                <select
                  name="dept"
                  id="dept"
                  className="reg-form-input"
                  onChange={(e) => {
                    setDepartment(e.target.value);
                    handleDept(e.target.value);
                  }}
                >
                  <option value="">Select Department</option>
                  {departmentData && departmentData !== undefined
                    ? departmentData.map((dept, index) => {
                        return (
                          <option key={index} value={dept.value}>
                            {dept.value}
                          </option>
                        );
                      })
                    : "No Faculty"}
                </select>
              </div>
            </div>

            <div className="reg-input-section">
              <div className="reg-block">
                <label htmlFor="course" className="obj-description">
                  Course
                </label>
                <select
                  name="course"
                  id="course"
                  className="reg-form-input"
                  onChange={(e) => setCourse(e.target.value)}
                >
                  <option value="">Select Course</option>
                  {courseData && courseData !== undefined
                    ? courseData.map((crs, index) => {
                        return (
                          <option key={index} value={crs.value}>
                            {crs.value}
                          </option>
                        );
                      })
                    : "No Faculty"}
                </select>
              </div>

              <div className="reg-block">
                <label htmlFor="level" className="obj-description">
                  Level
                </label>
                <select
                  name="level"
                  id="level"
                  className="reg-form-input"
                  onChange={(e) => setLevel(e.target.value)}
                  required
                >
                  <option value=""></option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="300">300</option>
                  <option value="400">400</option>
                </select>
              </div>
            </div>

            <div className="reg-input-section">
              <div className="reg-block">
                <label htmlFor="middleName" className="obj-description">
                  Matric Number
                </label>
                <input
                  type="text"
                  name="matricNumber"
                  id="matric-number"
                  className="reg-form-input"
                  onChange={(e) => setMatricNo(e.target.value)}
                  required
                />
              </div>
              <div className="reg-block">
                <label htmlFor="course" className="obj-description">
                  Bank Name
                </label>
                <select
                  name="bankName"
                  id="bank-name"
                  className="reg-form-input"
                  onChange={(e) => setBankDetails({
                    ...bankDetails,
                    name: e.target.value
                  })}
                >
                  <option value="">Select Bank</option>
                  {bankList.map((bank, index) => (
                  <option key={index} value={bank}>
                  {bank}
                  </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="reg-input-section">
              <div className="reg-block">
                <label htmlFor="bankAccountNumber" className="obj-description">
                  Bank Account Number
                </label>
                <input
                  type="text"
                  name="bankAccountNumber"
                  id="bank-acc-num"
                  className="reg-form-input"
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      accountNumber: e.target.value
                    })
                  }
                  required
                />
              </div>

              <div className="reg-block">
                <label htmlFor="bankSortCode" className="obj-description">
                  Bank Sort Code
                </label>
                <input
                  type="text"
                  name="bankSortCode"
                  id="sort-code"
                  className="reg-form-input"
                  onChange={(e) =>
                    setBankDetails({ ...bankDetails, sortCode: e.target.value })
                  }
                  required
                />
              </div>
            </div>
            <div className="reg-input-section">
              <div className="reg-block">
                <label htmlFor="department" className="obj-description">
                  Password
                </label>
                <div className="password-container">
                  <input
                    type={passwordShow ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder=""
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="reg-form-input"
                  />
                  <img
                    src={
                      passwordShow ? `${passwordEye}` : `${passwordEyeSlash}`
                    }
                    alt=""
                    className="eye2"
                    onClick={toggleShow}
                  />
                </div>
              </div>
            </div>
            <div className="reg-input-section">
              <div className="reg-block cntr">
                <button
                  className="login-btn"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <div className="center-load">
                      <span className="basic"></span>
                    </div>
                  ) : (
                    `Register`
                  )}
                </button>
              </div>
            </div>
            <div className={regFail ? "post-fail" : ""}>
              {regFail ? (
                <div className="result-icon">
                  <img src={excl} />
                  {errMessage}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={success ? "post-success" : ""}>
              {success ? (
                <div className="result-icon">
                  <img src={tick} />
                  Registration Successful!
                </div>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
