import React, { useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import buLogo from "../Images/bulogo.png";
import logoStack from "../Images/logo_stack.png";
import menuBtn from "../Icons/bx-menu-alt-left.svg";
import arrowBtn from "../Icons/bx-left-arrow-alt.svg";
import dashboardIcon from "../Icons/bxs-dashboard.svg";
import changePassword from "../Icons/change-password.svg";
import editDetails from "../Icons/user-edit.svg";
import formUploadIcon from "../Icons/file-lines-solid.svg";
import createIcon from "../Icons/user-add.svg";
import iconTick from "../Icons/user-tick.svg";
import studentIcon from "../Icons/student.svg";
import batch from "../Icons/batch.svg";
import timer from "../Icons/timer.svg";
import supervisorIcon from "../Icons/supervisor.svg";
import collateIcon from "../Icons/tick-square.svg";
import logoutIcon from "../Icons/bxs-log-out.svg";
import axios from "../api/axios";
import { ContextCoordinator } from "../Context/Context";
import { useContext } from "react";

import "../CSS/ProfileNav.css";

function CoordinatorNav(props) {
  const [navBarOpen, setNavBarOpen] = useState(false);
  const { coordinator, dispatch } = useContext(ContextCoordinator);

  const navToggle = () => {
    setNavBarOpen(!navBarOpen);
  };

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const navigate = useNavigate();

  async function logOut(e) {
    try {
      const res = await axios.post(`/api/auth/logout`);
      dispatch({ type: "LOGOUT_COORDINATOR" });
      navigate("/coordinatorlogin");
    } catch (err) {}
  }

  return (
    <div>
      <nav className={`sidebar co ${navBarOpen ? "active" : ""}`}>
        <div className="logo-content">
          <div className="logo">
            <img src={buLogo} alt="" className="logo-img" />
            <img src={logoStack} alt="babcock" className="logo-img-name" />
          </div>
          <button
            className="sidebar-burger"
            id="sidebar-btn"
            onClick={navToggle}
          >
            <div>
              <img
                src={menuBtn}
                alt=""
                className={`sidebar-btn btn-h ${navBarOpen ? "active" : ""}`}
              />
            </div>
            <div>
              <img
                src={arrowBtn}
                alt=""
                className={`sidebar-btn btn-x ${navBarOpen ? "active" : ""}`}
              />
            </div>
          </button>
        </div>

        <ul className="sidebar-list">
          <li>
            <NavLink
              to="/coordinator/index"
              className={
                splitLocation[2] === "index"
                  ? "sidebar-link active-link"
                  : "sidebar-link"
              }
              title="Dashboard"
            >
              <img src={dashboardIcon} alt="" />
              <span className="link-name">Dashboard</span>
            </NavLink>
            {/* <span className="tooltip">Dashboard</span> */}
          </li>

          <li>
            <NavLink
              to="/coordinator/changepassword"
              className={
                splitLocation[2] === "changepassword"
                  ? "sidebar-link active-link"
                  : "sidebar-link"
              }
              title="Change Password"
            >
              <img src={changePassword} alt="" />
              <span className="link-name">Change Password</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/coordinator/editdetails"
              className={
                splitLocation[2] === "editdetails"
                  ? "sidebar-link active-link"
                  : "sidebar-link"
              }
              title="Edit Details"
            >
              <img src={editDetails} alt="" />
              <span className="link-name">Edit Details</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/coordinator/uploadform"
              className={
                splitLocation[2] === "uploadform"
                  ? "sidebar-link active-link"
                  : "sidebar-link"
              }
              title="Upload Forms"
            >
              <img src={formUploadIcon} alt="" />
              <span className="link-name">Upload Forms</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/coordinator/createsupervisor"
              className={
                splitLocation[2] === "createsupervisor"
                  ? "sidebar-link active-link"
                  : "sidebar-link"
              }
              title="Create Supervisor Account"
            >
              <img src={createIcon} alt="" />
              <span className="link-name">Create Supervisor Account</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/coordinator/assigninspector"
              className={
                splitLocation[2] === "assigninspector"
                  ? "sidebar-link active-link"
                  : "sidebar-link"
              }
              title="Assign Inspection Supervisor"
            >
              <img src={iconTick} alt="" />
              <span className="link-name">Assign Inspection Supervisor</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/coordinator/assigndefence"
              className={
                splitLocation[2] === "assigndefence"
                  ? "sidebar-link active-link"
                  : "sidebar-link"
              }
              title="Assign Defence Supervisor"
            >
              <img src={iconTick} alt="" />
              <span className="link-name">Assign Defence Supervisor</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/coordinator/supervisors"
              className={
                splitLocation[2] === "supervisors"
                  ? "sidebar-link active-link"
                  : "sidebar-link"
              }
              title="Registered Supervisors"
            >
              <img src={supervisorIcon} alt="" />
              <span className="link-name">Registered Supervisors</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/coordinator/createcoordinator"
              className={
                splitLocation[2] === "createcoordinator"
                  ? "sidebar-link active-link"
                  : "sidebar-link"
              }
              title="Create Coordinator Account"
            >
              <img src={createIcon} alt="" />
              <span className="link-name">Create Coordinator Account</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/coordinator/students"
              className={
                splitLocation[2] === "students"
                  ? "sidebar-link active-link"
                  : "sidebar-link"
              }
              title="Registered Students"
            >
              <img src={studentIcon} alt="" />
              <span className="link-name">Registered Students</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/coordinator/batchgrading"
              className={
                splitLocation[2] === "batchgrading"
                  ? "sidebar-link active-link"
                  : "sidebar-link"
              }
              title="Batch Grading"
            >
              <img src={batch} alt="" />
              <span className="link-name">Batch Grading</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/coordinator/setregistration"
              className={
                splitLocation[2] === "setregistration"
                  ? "sidebar-link active-link"
                  : "sidebar-link"
              }
              title="Set Registration Time"
            >
              <img src={timer} alt="" />
              <span className="link-name">Set Registration Time</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/coordinator/collategrades"
              className={
                splitLocation[2] === "collategrades"
                  ? "sidebar-link active-link"
                  : "sidebar-link"
              }
              title="Collate Grade"
            >
              <img src={collateIcon} alt="" />
              <span className="link-name">Collate Grades</span>
            </NavLink>
          </li>
        </ul>
        <div className="logout-co">
          <a className="sidebar-link" onClick={logOut}>
            <img src={logoutIcon} alt="" />
            <span className="link-name">Logout</span>
            {/* <span className="tooltip">Logout</span> */}
          </a>
        </div>
      </nav>
    </div>
  );
}

export default CoordinatorNav;
